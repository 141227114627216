import React, { useRef } from "react"
import PropTypes from "prop-types"

import { addImage, container, image, deletebtn } from "./ProductFormImages.module.css"

function ProductFormImages({ productImages, setProductImages }) {
  const add = src => setProductImages([...productImages, src])
  const remove = src => setProductImages(productImages.filter(x => x !== src))

  const input = useRef()

  return (
    <>
      <div>
        <label className={addImage}>
          URL{" "}
          <input
            type="text"
            placeholder="https://..."
            ref={input}
          />
          <button
            onClick={() => {
              add(input.current.value)
              input.current.value = ""
            }}
          >
            Add Image
          </button>
        </label>
      </div>
      <div className={container}>
        {productImages.map(src => (
          <div className={image} key={src}>
            <button onClick={() => remove(src)} className={deletebtn}>
              &times;
            </button>
            <img src={src} alt={"Could not load image"} />
          </div>
        ))}
      </div>
    </>
  )
}

ProductFormImages.propTypes = {
  productImages: PropTypes.array.isRequired,
  setProductImages: PropTypes.func.isRequired,
}

export default ProductFormImages
